import * as React from 'react';

import * as UI from '../components';
import { AppHeader } from './header';
import { LayoutContext } from '../context';

export const AppContent: React.FC = ({ children }) => {
  const { state } = React.useContext(LayoutContext);

  return (
    <UI.Layout $wide={!state.sidebar.isOpen}>
      <AppHeader />
      <UI.Content>{children}</UI.Content>
    </UI.Layout>
  );
};
