import React, { FC, memo, useCallback, useMemo } from 'react';
import { Form, DatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, useField } from 'formik';
import { Moment } from 'moment';
import { twoLevelShallowEqualObjects } from '@core/utils';

export type DateFieldProps = FieldConfig & { item?: FormItemProps; input?: DatePickerProps };
export type MemoizedDateFieldProps = {
  item?: FormItemProps;
  input?: DatePickerProps;
  field: any;
  meta: any;
};

const MemoizedDateField = memo<MemoizedDateFieldProps>(({ meta, input, field, item }) => {
  const style = useMemo(() => ({ width: '100%' }), []);

  return (
    <Form.Item {...item} validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error}>
      <DatePicker style={style} {...field} {...input} />
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const DateField: FC<DateFieldProps> = ({ item, input, ...props }) => {
  const [{ onChange: baseOnChange, ...field }, meta] = useField(props);

  const onChange = useCallback(
    (value: Moment | null) => {
      baseOnChange(field.name)({ target: { type: 'text', value } } as any);
    },
    [baseOnChange, field.name],
  );

  const newField = useMemo(() => ({ ...field, onChange }), [field, onChange]);

  return <MemoizedDateField field={newField} meta={meta} item={item} input={input} />;
};
