import React, { memo } from 'react';
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';

import { NextTableRow } from './row';
import { StyledNextTable } from '../styled';

type ITableBodyProps = {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  rows: Row[];
  prepareRow: (row: Row<any>) => void;
  selectedRowIds: Record<string | number, true>;
};

export const NextTableBody = memo<ITableBodyProps & { getRowProps?: Function }>(
  ({ getTableBodyProps, rows, prepareRow, selectedRowIds, getRowProps }) => {
    return (
      <StyledNextTable.TBody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          return <NextTableRow selected={selectedRowIds[row.id]} key={row.id} getRowProps={getRowProps} prepareRow={prepareRow} row={row} />;
        })}
      </StyledNextTable.TBody>
    );
  },
  (prev, curr) => {
    return prev.rows === curr.rows && prev.selectedRowIds === curr.selectedRowIds;
  },
);
