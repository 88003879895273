import { useService } from '@core/inversify-react';
import { SSSelectEntities } from '@core/types';
import { twoLevelShallowEqualObjects } from '@core/utils';
import { Form, Select } from 'antd';
import { useField } from 'formik';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { GetSSSelectOpsRepoType, IGetSSSelectOpsRepo } from '../repos';

interface ServerSideSelectProps {
  throttle?: number;
  search_key: string;
  urlPath: string;
  name: string;
  per_page?: number;
  label: string;
  placeholder: string;
  mapper(data: any[]): SSSelectEntities[];
}

let currentValue: string;

export const ServerSideSelect = memo<ServerSideSelectProps>(({ urlPath, search_key, throttle, name, label, placeholder, mapper, per_page = 10000 }) => {
  const __THROTTLE__ = 500;

  const getShopsRepo = useService<IGetSSSelectOpsRepo>(GetSSSelectOpsRepoType);

  const timeout = useRef<any>();
  const [data, setData] = useState<any[]>([]);
  const [field, , helpers] = useField({ name });

  const fetchData = useCallback(async () => {
    const result = await getShopsRepo.execute({ page: 1, per_page, [`${search_key}`]: currentValue }, urlPath);
    if (result.status === 200) {
      setData(result.response as any[]);
    } else {
      throw new Error(result.response);
    }
  }, [urlPath, search_key, getShopsRepo, per_page]);

  const fetchFromApi = useCallback(
    (val: string) => {
      if (timeout) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      currentValue = val;
      timeout.current = setTimeout(fetchData, throttle || __THROTTLE__);
    },
    [fetchData, throttle],
  );

  const handleChange = (newValue: string) => {
    helpers.setValue(newValue);
  };

  const handleSearch = useCallback(
    (newValue: string) => {
      if (newValue) {
        helpers.setValue(newValue);
        fetchFromApi(newValue);
      } else {
        setData([]);
      }
    },
    [fetchFromApi, helpers],
  );

  const options = useMemo(() => {
    const mapped = mapper(data);
    return mapped.map((option) => (
      <Select.Option value={option.value} key={option.value}>
        {option.label}
      </Select.Option>
    ));
  }, [data, mapper]);

  return (
    <Form.Item name={name} style={{ width: '100%' }} label={label}>
      <Select
        style={{ width: '100%' }}
        allowClear
        defaultValue={field.value || null}
        showSearch
        value={field.value}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
      >
        {options}
      </Select>
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);
