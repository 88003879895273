const routes = {
  home: { index: '/' },
  login: { index: '/login' },
  bonaz: {
    index: '/bonaz',
    conversions: '/bonaz/conversions',
    offers: '/bonaz/offers',
  },
  quickSearch: {
    index: '/quick_search',
    declaration: '/quick_search/declarations/:id',
    order: '/quick_search/orders/:id',
  },
  dashboard: {
    index: '/dashboard',
    special: {
      balance: {
        details: '/dashboard/special/balance/details',
      },
      declarations: {
        details: '/dashboard/special/declarations/details',
      },
    },
    transactions: {
      index: '/dashboard/transactions/:model',
    },
    orders: {
      info: '/dashboard/orders/info',
    },
    declarations: {
      info: '/dashboard/declarations/info',
    },
    clients: {
      info: '/dashboard/clients/info',
    },
    couriers: {
      info: '/dashboard/couriers/info',
      handovers: {
        couriers: '/dashboard/couriers/handovers/details',
        declarations: '/dashboard/couriers/handovers/declarations',
      },
    },
  },
  orders: {
    index: '/orders',
    create: '/orders/create',
    edit: '/orders/:id/edit',
    info: '/orders/:id/info',
    reject: '/orders/reject',
    states: {
      timeline: '/orders/:id/state/timeline',
      history: '/orders/:id/state/history',
    },
    declaration: '/orders/declarations/:id',
    statistics: '/orders/statistics',
  },
  declarations: {
    index: '/declarations',
    statistics: '/declarations/statistics',
    return: '/declarations/:id/return',
    create: {
      index: '/declarations/create',
      shop: '/declarations/create/shop',
    },
    edit: {
      index: '/declarations/:id/edit',
      shop: '/declarations/:id/edit/shop',
    },
    info: '/declarations/:id/info',
    states: {
      timeline: '/declarations/:id/state/timeline',
      history: '/declarations/:id/state/history',
    },
    flights: '/declarations/flights/:id',
    orders: '/declarations/orders/:id',
    quickPay: '/declarations/quick_pay/:id',
    conflicted: {
      index: '/declarations/conflicted',
      create: '/declarations/conflicted/create',
      edit: '/declarations/conflicted/:id/edit',
    },
    accept: '/declarations/accept',
    remote: {
      customs: '/declarations/remote/customs',
      index: '/declarations/remote',
      info: '/declarations/remote/info/:id',
      user: '/declarations/remote/user/info/:id',
      deleted: {
        index: '/declarations/remote/deleted',
      },
    },
    bulk: {
      handover: '/declarations/bulk/handover',
    },
    thisMonth: {
      info: '/declarations/this_month/:userId/:id/info',
      index: '/declarations/this_month/:userId',
    },
  },
  tickets: {
    index: '/tickets',
    create: '/tickets/create',
    edit: '/tickets/:id/edit',
    info: '/tickets/:id/info',
    states: {
      history: '/tickets/:id/state/history',
    },
    categories: { index: '/tickets/categories' },
  },
  couriers: {
    index: '/couriers',
    create: '/couriers/create',
    edit: '/couriers/:id/edit',
    info: '/couriers/:id/info',
    states: {
      timeline: '/couriers/:id/state/timeline',
      history: '/couriers/:id/state/history',
    },
    statistics: '/couriers/statistics',
    declarations: '/couriers/declarations/:id',
  },
  regions: {
    index: '/regions',
    create: '/regions/create',
    edit: '/regions/:id/edit',
  },
  models: {
    index: '/models',
  },
  states: {
    index: '/states',
    create: '/states/create',
    edit: '/states/:id/edit',
  },
  productTypes: {
    index: '/product_types',
    create: '/product_types/create',
    edit: '/product_types/:id/edit',
  },
  plans: {
    index: '/plans',
    create: '/plans/create',
    edit: '/plans/:id/edit',
    categories: '/plans/categories',
  },
  shops: {
    index: '/shops',
    create: '/shops/create',
    edit: '/shops/:id/edit',
  },
  users: {
    index: '/users/:type',
    statistics: '/users/:type/statistics',
    create: '/users/:type/create',
    edit: '/users/:type/:id/edit',
    permissions: '/users/:type/:id/permissions',
  },
  ticketTemplates: {
    index: '/ticket_templates',
    create: '/ticket_templates/create',
    edit: '/ticket_templates/:id/edit',
    info: '/ticket_templates/:id/info',
  },
  notificationTemplates: {
    index: '/notification_templates',
    create: '/notification_templates/create',
    edit: '/notification_templates/:id/edit',
    info: '/notification_templates/:id/info',
    params: {
      index: '/notification_templates/params',
      create: '/notification_templates/params/create',
      edit: '/notification_templates/params/:id/create',
    },
  },
  about: {
    index: '/about',
  },
  transportationConditions: {
    index: '/transportation_conditions',
  },
  popups: {
    index: '/popups',
    create: '/popups/create',
  },
  config: {
    shops: {
      index: '/config/shops',
      create: '/config/shops/create',
      edit: '/config/shops/:id/edit',
    },
    returnTypes: {
      index: '/config/return_types',
      create: '/config/return_types/create',
      edit: '/config/return_types/:id/edit',
    },
  },
  logs: {
    index: '/logs',
    info: '/logs/:id/info',
    declarations: {
      info: '/logs/declarations/:id',
    },
    orders: {
      info: '/logs/orders/:id',
    },
    couriers: {
      info: '/logs/couriers/:id',
    },
  },
  archive: {
    sms: {
      index: '/archive/sms',
      bulk: {
        index: '/archive/sms/bulk',
      },
    },
    email: {
      index: '/archive/email',
      bulk: {
        index: '/archive/email/bulk',
        templates: {
          create: '/archive/email/bulk/template/create',
        },
      },
    },
    state: {
      index: '/archive/state',
      declarations: {
        info: '/archive/state/declarations/:id',
      },
      orders: {
        info: '/archive/state/orders/:id',
      },
      couriers: {
        info: '/archive/state/couriers/:id',
      },
    },
  },
  faq: {
    index: '/faq',
    create: '/faq/create',
    edit: '/faq/:id/edit',
  },
  news: {
    index: '/news',
    create: '/news/create',
    edit: '/news/:id/edit',
  },
  banners: {
    index: '/banners',
    create: '/banners/create',
  },
  transactions: {
    index: '/transactions',
    create: '/transactions/create',
    statistics: '/transactions/statistics',
  },
  flights: {
    index: '/flights',
    create: '/flights/create',
    close: '/flights/:id/close/:type',
    info: '/flights/:id/info',
    updateThisMonth: '/flights/:id/this_month/update',
    bulkManifest: '/flights/:id/bulk_manifest',
  },
  dnsQueues: {
    index: '/dns/queues',
  },
};

export default routes;
