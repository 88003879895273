import { TableState } from '@core/table';

export enum TableActionTypes {
  RESET = '@table/reset',
  MERGE_STATE = '@table/merge/state',
  SET_GLOBAL_FILTER = '@table/set/globalFilter',
  SET_FILTERS = '@table/set/filters',
  SET_FILTER_BY_ID = '@table/set/filters/byId',
  SET_SORT_BY = '@table/set/sortBy',
  SET_PAGE_INDEX = '@table/set/pageIndex',
  SET_PAGE_SIZE = '@table/set/pageSize',
  SET_SELECTED_ROW_IDS = '@table/set/selectedRowIds',
  SET_HIDDEN_COLUMNS = '@table/set/hiddenColumns',
  SELECT_ALL = '@table/set/selectAll',
  FETCHING_STARTED = '@table/fetching/started',
  FETCHING_SUCCEED = '@table/fetching/succeed',
  FETCHING_FAILED = '@table/fetching/failed',
}

export interface TableResetAction {
  type: TableActionTypes.RESET;
}

export interface TableSetGlobalFilterAction {
  type: TableActionTypes.SET_GLOBAL_FILTER;
  value: string;
}

export interface TableSetFiltersAction {
  type: TableActionTypes.SET_FILTERS;
  filters: any[];
}

export interface TableMergeStateAction {
  type: TableActionTypes.MERGE_STATE;
  state: Partial<TableState>;
}

export interface TableSetFilterByIdAction {
  type: TableActionTypes.SET_FILTER_BY_ID;
  id: string;
  value: any;
}

export interface TableSetSortByAction {
  type: TableActionTypes.SET_SORT_BY;
  sortBy: any[];
}

export interface TableSetPageIndexAction {
  type: TableActionTypes.SET_PAGE_INDEX;
  pageIndex: number;
}

export interface TableSetPageSizeAction {
  type: TableActionTypes.SET_PAGE_SIZE;
  pageSize: number;
}

export interface TableSetHiddenColumnsAction {
  type: TableActionTypes.SET_HIDDEN_COLUMNS;
  hiddenColumns: string[];
}

export interface TableSetSelectedRowIdsAction {
  type: TableActionTypes.SET_SELECTED_ROW_IDS;
  selectedRowIds: Record<string | number, true>;
}

export interface TableSelectAllAction {
  type: TableActionTypes.SELECT_ALL;
}

export interface TableFetchingStartedAction {
  type: TableActionTypes.FETCHING_STARTED;
}

export interface TableFetchingSucceedAction {
  type: TableActionTypes.FETCHING_SUCCEED;
  data: any[];
  total: number;
  meta?: Record<string, any>;
}

export interface TableFetchingFailedAction {
  type: TableActionTypes.FETCHING_FAILED;
  error: string | undefined;
}

export type TableActions =
  | TableResetAction
  | TableFetchingStartedAction
  | TableFetchingSucceedAction
  | TableFetchingFailedAction
  | TableSetFiltersAction
  | TableSetSortByAction
  | TableSetPageSizeAction
  | TableSetPageIndexAction
  | TableSetHiddenColumnsAction
  | TableSetFilterByIdAction
  | TableSetSelectedRowIdsAction
  | TableSelectAllAction
  | TableMergeStateAction
  | TableSetGlobalFilterAction;
