import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import ReduxThunk from 'redux-thunk';
import reducers from '../../reducers';

const epicMiddleware = createEpicMiddleware();

const initialState: Record<string, any> = {};
const middlewares: Middleware[] = [ReduxThunk, epicMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
