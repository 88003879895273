import styled from 'styled-components';

export const MasonryGrid = styled.div`
  columns: 6 300px;
  column-gap: 16px;
`;

export const MasonryCol = styled.div`
  display: inline-block;
  width: 100%;
  padding-bottom: 16px;
`;
