import React, { FC, MouseEvent as ReactMouseEvent, useCallback, useRef } from 'react';
import styled from 'styled-components';

const ScrollContainer = styled.div`
  cursor: grab;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  overflow: auto;
`;

export const DragScroll: FC<{ className?: string }> = ({ className, children }) => {
  const posRef = useRef({ top: 0, left: 0, x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = useCallback((event: MouseEvent) => {
    // How far the mouse has been moved
    const dx = event.clientX - posRef.current.x;
    const dy = event.clientY - posRef.current.y;

    if (containerRef.current) {
      const elem = containerRef.current;
      // Scroll the element
      elem.scrollTop = posRef.current.top - dy;
      elem.scrollLeft = posRef.current.left - dx;
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    if (containerRef.current) {
      const elem = containerRef.current;

      elem.style.cursor = 'grab';
      elem.style.removeProperty('user-select');

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (event: ReactMouseEvent<HTMLDivElement>) => {
      if (containerRef.current) {
        const elem = containerRef.current;

        elem.style.cursor = 'grabbing';
        elem.style.userSelect = 'none';

        posRef.current = { left: elem.scrollLeft, top: elem.scrollTop, x: event.clientX, y: event.clientY };
      }

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp],
  );

  return (
    <ScrollContainer ref={containerRef} onMouseDown={handleMouseDown} className={className}>
      {children}
    </ScrollContainer>
  );
};
