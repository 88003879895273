import { useEffect } from 'react';

export const useDebounceEffect = (cb, time) => {
  useEffect(() => {
    const timeout = setTimeout(cb, time);

    return () => {
      clearTimeout(timeout);
    };
  }, [cb, time]);
};
