import React, { memo } from 'react';
import { StyledNextTable } from '../styled';

export const TablePagination = memo<any>(({ pageIndex, total, pageSize, pageOptions, setPageSize, gotoPage }) => {
  return (
    <StyledNextTable.Pagination
      current={pageIndex + 1}
      className='next-table-pagination'
      total={total}
      pageSize={pageSize}
      pageSizeOptions={pageOptions}
      onChange={(page) => gotoPage(page - 1)}
      onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
      showSizeChanger
      showQuickJumper
      showLessItems={true}
      responsive={true}
    />
  );
});
