import styled from 'styled-components';

export const OnlyDatePicker = styled.div.attrs({ className: 'only-date-picker' })`
  & > * {
    position: unset !important;
  }

  .ant-picker {
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
  }

  .ant-picker-dropdown {
    position: unset !important;
    margin-bottom: -35px;
  }

  .ant-picker-panel-container {
    box-shadow: none;
  }
`;
