import { useRef } from 'react';
import { shallowEqualObjects } from 'shallow-equal';

export const twoLevelShallowEqualObjects = (prevObject: any, currObject: any): boolean => {
  if (typeof prevObject !== 'object' || typeof currObject !== 'object') {
    return false;
  }

  for (let key in prevObject) {
    if (prevObject.hasOwnProperty(key) && currObject.hasOwnProperty(key)) {
      const prevNestedObject = prevObject[key];
      const currNestedObject = currObject[key];

      if (!shallowEqualObjects(prevNestedObject, currNestedObject)) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};

export const useShallowCompareMemoize = <T>(value: T): T => {
  const ref = useRef<T>(value);

  if (!twoLevelShallowEqualObjects(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};
