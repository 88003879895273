import {
  TableResetAction,
  TableSetFiltersAction,
  TableSetFilterByIdAction,
  TableSetSortByAction,
  TableSetPageIndexAction,
  TableSetPageSizeAction,
  TableSetHiddenColumnsAction,
  TableSetSelectedRowIdsAction,
  TableSelectAllAction,
  TableFetchingStartedAction,
  TableFetchingSucceedAction,
  TableFetchingFailedAction,
  TableMergeStateAction,
  TableSetGlobalFilterAction,
  TableActionTypes,
} from './action-types';
import { TableState } from '@core/table';

export const nextTableResetAction = (): TableResetAction => ({
  type: TableActionTypes.RESET,
});

export const nextTableMergeStateAction = (state: Partial<TableState>): TableMergeStateAction => ({
  type: TableActionTypes.MERGE_STATE,
  state,
});

export const nextTableSetFiltersAction = (filters: any[]): TableSetFiltersAction => ({
  type: TableActionTypes.SET_FILTERS,
  filters,
});

export const nextTableSetGlobalFilterAction = (value: string): TableSetGlobalFilterAction => ({
  type: TableActionTypes.SET_GLOBAL_FILTER,
  value,
});

export const nextTableSetFiltersByIdAction = (id: string, value: any): TableSetFilterByIdAction => ({
  type: TableActionTypes.SET_FILTER_BY_ID,
  id,
  value,
});

export const nextTableSetSortByAction = (sortBy: any[]): TableSetSortByAction => ({
  type: TableActionTypes.SET_SORT_BY,
  sortBy,
});

export const nextTableSetPageIndexAction = (pageIndex: number): TableSetPageIndexAction => ({
  type: TableActionTypes.SET_PAGE_INDEX,
  pageIndex,
});

export const nextTableSetPageSizeAction = (pageSize: number): TableSetPageSizeAction => ({
  type: TableActionTypes.SET_PAGE_SIZE,
  pageSize,
});

export const nextTableSetHiddenColumns = (hiddenColumns: string[]): TableSetHiddenColumnsAction => ({
  type: TableActionTypes.SET_HIDDEN_COLUMNS,
  hiddenColumns,
});

export const nextTableSetSelectedRowIdsAction = (selectedRowIds: Record<string | number, true>): TableSetSelectedRowIdsAction => ({
  type: TableActionTypes.SET_SELECTED_ROW_IDS,
  selectedRowIds,
});

export const nextTableSelectAllAction = (): TableSelectAllAction => ({
  type: TableActionTypes.SELECT_ALL,
});

export const nextTableFetchDataStartedAction = (): TableFetchingStartedAction => ({
  type: TableActionTypes.FETCHING_STARTED,
});

export const nextTableFetchDataSucceedAction = ({ total, data, meta }: { total: number; data: any[]; meta?: Record<string, any> }): TableFetchingSucceedAction => ({
  type: TableActionTypes.FETCHING_SUCCEED,
  total,
  data,
  meta,
});

export const nextTableFetchDataFailedAction = (error?: string | undefined): TableFetchingFailedAction => ({
  type: TableActionTypes.FETCHING_FAILED,
  error,
});
