import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

export type IGetSSSelectOpsRepoResponse = IRepoResponse<200, any[]> | IRepoResponse<400 | 500, string>;

export type IGetSSSelectOpsRepo = IRepo<[any, string], IGetSSSelectOpsRepoResponse>;

export const GetSSSelectOpsRepoType = Symbol.for('GetSSSelectOpsRepo');

@bind(GetSSSelectOpsRepoType)
export class GetSSSelectOpsRepo implements IGetSSSelectOpsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object, url: string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker(url, query) });

      if (result.status === 200) {
        const data = result.data.data;
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
