import { LoginDto, LoginDtoPersistence } from '@modules/auth/dto/login.dto';

export class LoginDtoMapper {
  public static toPersistence(dto: LoginDto): LoginDtoPersistence {
    return {
      email: dto.email,
      password: dto.password,
    };
  }
}

export class LoginErrorMapper {
  public static toDomain(errors) {
    return {
      email: errors.email.join(', '),
      password: errors.password.join(', '),
    };
  }
}
