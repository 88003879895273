import { object2Search } from './object-to-search';

export const urlMaker = (pathname: string, params: Record<string, any> = {}) => {
  const host: string = process.env.REACT_APP_API_HOST || '';

  const stringParams: string = object2Search(params);

  return host + pathname + stringParams;
};

export const pathMaker = (pathname: string, params: Record<string, string | number | boolean> = {}) => {
  return Object.entries(params)
    .filter(([_, value]) => !!value)
    .reduce((acc, [key, value]) => {
      return acc.replace(':' + key, value.toString());
    }, pathname);
};
