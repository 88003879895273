import { injectable } from 'inversify';
import { BusEvent, BusSubscriber, IEventBus } from './intefaces';

@injectable()
export class EventBus implements IEventBus {
  private subscribes: Record<string, BusSubscriber[]> = {};
  private events: BusEvent[] = [];

  constructor() {
    this.publish = this.publish.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  subscribe(eventType: string, callback: BusSubscriber, receiveCold: number = 0) {
    if (!this.subscribes[eventType]) {
      this.subscribes[eventType] = [];
    }

    this.subscribes[eventType].push(callback);

    if (receiveCold) {
      const events = this.events.reverse().filter((event) => event.type === eventType);

      events.slice(0, receiveCold).forEach((event) => callback(event.payload, true));
    }
  }

  publish(event: BusEvent, temporary: boolean = false) {
    const newLength = this.events.push(event);

    const callbacks = this.subscribes[event.type] || [];

    callbacks.forEach((callback) => callback(event.payload, false));

    if (temporary) {
      setTimeout(() => {
        this.events.splice(newLength - 1, 1);
        console.log(this.events);
      }, 3000);
    }
  }

  unsubscribe(eventType: string, callback: BusSubscriber) {
    if (!this.subscribes[eventType]) {
      this.subscribes[eventType] = [];
    }

    const foundIndex = this.subscribes[eventType].findIndex((_callback) => _callback === callback);

    if (foundIndex !== -1) {
      this.subscribes[eventType].splice(foundIndex, 1);
    }
  }
}
