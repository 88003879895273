import { formDataFlat } from './form-data-flat';

export const object2Search = (object: Record<string, any>): string => {
  const flattened = formDataFlat(object);
  const arrayedObject = Object.entries(flattened);
  return arrayedObject.length
    ? arrayedObject.reduce((acc, [key, value], index) => {
        return acc + (index ? '&' : '?') + `${key}=${value}`;
      }, '')
    : '';
};
