import styled from 'styled-components';
import { Layout as BaseLayout } from 'antd';

export const Layout = styled(BaseLayout)<{ $wide?: boolean }>`
  margin-left: 0;
  transition-delay: 0.3s;

  @media screen and (min-width: 992px) {
    margin-left: ${({ $wide }) => ($wide ? '46px' : '224px')};
  }
`;

export const Content = styled(BaseLayout.Content)`
  min-height: calc(100vh - 46px);
  padding: 12px;
  margin-top: 46px;
`;
