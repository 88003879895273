import { createSelector, OutputSelector } from 'reselect';
import { IAppState } from '../interfaces/app-state.interface';
import { IAuthState } from '../interfaces/auth/state';

export const baseModelSelector = <T = any>(key: string): OutputSelector<any, T, any> => {
  return createSelector<IAppState, any, T>(
    (state: IAppState) => state[key],
    (page) => page,
  );
};

export const permissionsSelector = (): OutputSelector<any, string[], any> => {
  return createSelector<IAppState, IAuthState | undefined, string[]>(
    (state) => state.auth,
    (auth) => {
      return auth?.data?.permissions || [];
    },
  );
};
