import * as React from 'react';

export type ILayoutContext = {
  state: { sidebar: { isOpen: boolean } };
  toggleSidebar: () => void;
};

const initialContext: ILayoutContext = {
  state: {
    sidebar: {
      isOpen: window.innerWidth > 992,
    },
  },
  toggleSidebar: () => {},
};

export const LayoutContext = React.createContext(initialContext);

export const LayoutProvider: React.FC = React.memo(({ children }) => {
  const [state, setState] = React.useState(initialContext.state);

  const toggleSidebar = React.useCallback(() => {
    setState((value) => ({ ...value, sidebar: { ...value.sidebar, isOpen: !value.sidebar.isOpen } }));
  }, []);

  return <LayoutContext.Provider value={{ state, toggleSidebar }}>{children}</LayoutContext.Provider>;
});
