import { Draft, produce } from 'immer';
import { IBannersState } from '../interfaces/banners/state';
import { IBannersActions } from '../interfaces/banners/actions';
import { BannersActionTypes } from '../constants/banners';

const initialState: IBannersState = {
  loading: false,
  data: [],
};

export default produce(
  (state: Draft<IBannersState> = initialState, action: IBannersActions): IBannersState => {
    switch (action.type) {
      case BannersActionTypes.FETCH_STARTED:
        state.error = undefined;
        state.loading = true;
        break;
      case BannersActionTypes.FETCH_SUCCEED:
        state.loading = false;
        state.data = action.payload;
        break;
      case BannersActionTypes.FETCH_FAILED:
        state.loading = false;
        state.error = action.payload;
        break;
    }

    return state;
  },
);
