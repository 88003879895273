import React, { memo, useCallback, useMemo } from 'react';
import { StyledNextTable } from '../styled';

export const NextTableRow = memo<any>(({ prepareRow, row, getRowProps }) => {
  prepareRow(row);
  const toggleRowSelectedProps = row.getToggleRowSelectedProps();
  const handleClick = useCallback(() => {
    toggleRowSelectedProps.onChange({
      target: { checked: !toggleRowSelectedProps.checked },
    });
  }, [toggleRowSelectedProps]);
  const extraProps = useMemo(() => (getRowProps ? getRowProps(row.id, row.original) : {}), [getRowProps, row.id, row.original]);
  return (
    <StyledNextTable.Tr $selected={toggleRowSelectedProps.checked} {...row.getRowProps({ onClick: handleClick, ...extraProps })}>
      {row.cells.map((cell: any) => {
        return <StyledNextTable.Td {...cell.getCellProps({ className: cell.column.className })}>{cell.render('Cell')}</StyledNextTable.Td>;
      })}
    </StyledNextTable.Tr>
  );
});
