export const selectFilterOption = (input, option) => {
  if (option.children instanceof Array) {
    return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
};

export const filterOption = (input, option) => {
  if (typeof option.children === 'string') {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else if (Array.isArray(option.children)) {
    return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else {
    return false;
  }
};

export const filterOptionStart = (input, option) => {
  if (typeof option.children === 'string') {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) === 0;
  } else if (Array.isArray(option.children)) {
    return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) === 0;
  } else {
    return false;
  }
};
