import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  background-color: #f0f2f5;
  padding-top: 20vh;
`;

export const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  width: 384px;
  border-radius: 0.5rem;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(10px);
`;
