import styled from 'styled-components';
import { Form } from 'antd';

export const AntForm = styled(Form)`
  margin-bottom: -20px;

  .ant-form-item {
    margin-bottom: 24px;
  }

  &.ant-form-large .ant-form-item-label > label {
    height: 24px;
  }

  .ant-form-item-checkbox .ant-form-item-control-input {
    min-height: 24px;
  }
`;
