import styled from 'styled-components';
import { rgba } from 'polished';

export const ActionBar = styled.div<{ $flex?: boolean; $visible?: boolean }>`
  display: ${({ $flex, $visible = true }) => ($visible ? ($flex ? 'flex' : 'block') : 'none')};
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const SelectionBar = styled.div`
  padding-left: 12px;
  color: ${rgba('#fff', 0.85)};

  & > [role='icon'] {
    margin-right: 6px;
  }
`;
