import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Theme } from '../theme';

export const CardLink = styled(NavLink)`
  display: block;
  background-color: white;
  padding: 3rem 0;
  text-align: center;
  height: 100%;
  color: ${Theme.colors.primary};
`;

export const CardLinkIcon = styled.span`
  font-size: 64px;
`;

export const CardLinkTitle = styled.h1`
  margin: 0;
`;
