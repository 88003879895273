import { produce } from 'immer';
import { ICounterState } from '../interfaces/counter/state.interface';
import { ICounterActions } from '../interfaces/counter/actions.interface';
import { CounterActionTypes } from '../constants/counter';

const initialState: ICounterState = {
  loading: false,
  data: {
    declarations: 0,
    orders: 0,
    couriers: 0,
    conflictedDeclarations: 0,
    tickets: 0,
  },
};

export default produce(
  (state: ICounterState = initialState, action: ICounterActions): ICounterState => {
    switch (action.type) {
      case CounterActionTypes.POLLING_STARTED:
        state.error = undefined;
        state.loading = true;
        break;
      case CounterActionTypes.POLLING_SUCCEED:
        state.loading = false;
        state.data = action.payload;
        break;
      case CounterActionTypes.POLLING_FAILED:
        state.loading = false;
        state.error = action.payload;
        break;
    }

    return state;
  },
);
