import { useEffect } from 'react';
import { useService } from '@core/inversify-react';
import { BusSubscriber, EventBusType, IEventBus } from '../intefaces';

export const useSub = (eventType: string | string[], callback: BusSubscriber, receiveCold: number = 0) => {
  const eventBus = useService<IEventBus>(EventBusType);

  useEffect(() => {
    if (typeof eventType === 'string') {
      eventBus.subscribe(eventType, callback, receiveCold);

      return () => {
        eventBus.unsubscribe(eventType, callback);
      };
    } else {
      eventType.forEach((eventType) => eventBus.subscribe(eventType, callback, receiveCold));

      return () => {
        eventType.forEach((eventType) => eventBus.unsubscribe(eventType, callback));
      };
    }
  }, [eventBus, receiveCold]); // eslint-disable-line react-hooks/exhaustive-deps
};
