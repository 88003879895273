import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { useLayout } from '@modules/layout';
import { useCounter } from '@modules/counter';
import { useEffect } from 'react';

export const useSidebar = () => {
  const { state: layout, toggleSidebar } = useLayout();
  const { state: counter } = useCounter();
  const history = useHistory();
  useEffect(() => {
    if (counter?.alarms?.new <= 0) return;

    if (counter.alarms?.new > 1) {
      history.push('/alarms', { alarmsTable: { mergeState: { filters: [{ id: 'status_id', value: 22 }] } } });
    } else {
      history.push(generatePath('/alarms/:id/new', { id: counter.alarms.last! }));
    }
  }, [counter?.alarms, history]);

  const { pathname } = useLocation();

  return { isOpen: layout.sidebar.isOpen, activeKey: pathname, toggleSidebar, counter };
};
