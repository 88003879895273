import { ICounter, ICounterPersistence } from '../interfaces';

export class CounterMapper {
  public static toDomain(counter: ICounterPersistence): ICounter {
    const lastAlarmId = counter.new_alarm_list.length ? Math.max(...counter.new_alarm_list.map((alarm) => alarm.id)) : null;
    return {
      alarms: {
        total: counter.total_alarms,
        new: counter.new_alarms,
        last: lastAlarmId,
      },
      inspections: {
        total: counter.total_inspection,
        new: counter.new_inspection,
      },
    };
  }
}
