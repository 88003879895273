import { produce } from 'immer';
import { INewsState } from '../interfaces/news/state';
import { INewsActions } from '../interfaces/news/actions';
import { NewsActionTypes } from '../constants/news';

const initialState: INewsState = {
  loading: false,
  data: [],
};

export default produce(
  (state: INewsState = initialState, action: INewsActions): INewsState => {
    switch (action.type) {
      case NewsActionTypes.FETCH_STARTED:
        state.error = undefined;
        state.loading = true;
        break;
      case NewsActionTypes.FETCH_SUCCEED:
        state.loading = false;
        state.data = action.payload;
        break;
      case NewsActionTypes.FETCH_FAILED:
        state.loading = false;
        state.error = action.payload;
        break;
    }

    return state;
  },
);
