import { IAuth } from '../interfaces';

export class AuthMapper {
  public static toDomain(auth: any): IAuth {
    return {
      accessToken: auth.access_token,
      refreshToken: auth.refresh_token,
      tokenType: auth.token_type,
      expiresIn: auth.expires_in,
    };
  }
}
