import React, { FC } from 'react';
import { Layout } from 'antd';

import { AppSidebar } from './sidebar';
import { AppContent } from './content';
import { LayoutProvider } from '../context';

export const AppLayout: FC = ({ children }) => {
  return (
    <LayoutProvider>
      <Layout>
        <AppSidebar />
        <AppContent>{children}</AppContent>
      </Layout>
    </LayoutProvider>
  );
};
