import { createContext } from 'react';
import { nextTableState } from './reducer';
import { TableContext } from '../types';

export const createNextTableContext = () =>
  createContext<TableContext>({
    state: nextTableState,
    selection: [],
    dispatch: () => null,
    setFilters: () => null,
    setPageIndex: () => null,
    setPageSize: () => null,
    setSelectedRowIds: () => null,
    setSortBy: () => null,
    setGlobalFilter: () => null,
    fetch: () => null,
    reset: () => null,
    resetSelection: () => null,
    setFilterById: () => null,
    selectAll: () => null,
    mergeState: () => {},
  });
