import { createContext, FC, useCallback, useMemo, useRef } from 'react';
import { TableState } from '../types';

export type TableCacheState = {
  set: (key: string, state: TableState) => void;
  get: (key: string) => TableState | undefined;
};

export const TableCacheContext = createContext<TableCacheState>({
  get: () => undefined,
  set: () => {},
});

export const TableCacheProvider: FC = ({ children }) => {
  const cacheRef = useRef<Record<string, TableState>>({});

  const set = useCallback((key: string, state: TableState) => {
    cacheRef.current[key] = state;
  }, []);

  const get = useCallback((key: string) => {
    return cacheRef.current[key];
  }, []);

  const value = useMemo(() => ({ set, get }), [get, set]);

  return <TableCacheContext.Provider value={value}>{children}</TableCacheContext.Provider>;
};
