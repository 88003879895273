import React, { memo, useCallback, useMemo } from 'react';
import { Form, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, FieldInputProps, FieldMetaProps, useField } from 'formik';

import { twoLevelShallowEqualObjects } from '@core/utils';
import { filterOption } from '../utils';

export type SelectFieldProps = FieldConfig & { item?: FormItemProps; input?: SelectProps<any> };
export type MemoizedSelectFieldProps = {
  item?: FormItemProps;
  input?: SelectProps<any>;
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
  children: any;
};

export const MemoizedSelectField = memo<MemoizedSelectFieldProps>(({ item, input, field, meta, children }) => {
  return (
    <Form.Item validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error} {...item}>
      <Select allowClear={true} showSearch={true} filterOption={filterOption} {...field} {...input}>
        {children}
      </Select>
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const SelectField = memo<SelectFieldProps>(({ item, input, children, ...props }) => {
  const [{ onChange: baseOnChange, ...field }, meta] = useField(props);

  const value = useMemo(() => field.value || null, [field.value]);

  const onChange = useCallback(
    (value) => {
      baseOnChange({ target: { name: field.name, value } } as any);
    },
    [baseOnChange, field.name],
  );

  const newField = useMemo(() => ({ ...field, value: value || undefined, onChange }), [field, onChange, value]);

  return (
    <MemoizedSelectField field={newField} meta={meta} input={input} item={item}>
      {children}
    </MemoizedSelectField>
  );
});
