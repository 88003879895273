import styled from 'styled-components';

export const FieldGroup = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

export const FieldGroupButtonWrapper = styled.div`
  padding-top: 32px;
`;
