import React, { memo, useCallback, useMemo } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, FieldMetaProps, useField } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { twoLevelShallowEqualObjects } from '@core/utils';

export type RichTextFieldProps = FieldConfig & { item?: FormItemProps; input?: any };
export type MemoizedRichTextFieldProps = {
  item?: FormItemProps;
  input?: any;
  field: any;
  meta: FieldMetaProps<any>;
};

const MemoizedRichTextField = memo<MemoizedRichTextFieldProps>(({ item, input, meta, field }) => {
  return (
    <Form.Item validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error} {...item}>
      <CKEditor editor={ClassicEditor} {...field} {...input} />
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const RichTextField = memo<RichTextFieldProps>(({ item, input, ...props }) => {
  const [{ onChange: baseOnChange, onBlur: baseOnBlur, ...field }, meta] = useField(props);

  const onChange = useCallback(
    (event, editor) => {
      const body = editor.getData();
      baseOnChange(field.name)(body);
    },
    [baseOnChange, field.name],
  );

  const onBlur = useCallback(() => {
    baseOnBlur(field.name);
  }, [baseOnBlur, field.name]);

  const value = useMemo(() => field.value || '', [field.value]);

  const newField = useMemo(() => ({ onChange, onBlur, data: value }), [onChange, onBlur, value]);

  return <MemoizedRichTextField field={newField} meta={meta} input={input} item={item} />;
});
