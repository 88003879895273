import { produce } from 'immer';
import { IFAQState } from '../interfaces/faq/state';
import { IFAQActions } from '../interfaces/faq/actions';
import { FAQActionTypes } from '../constants/faq';

const initialState: IFAQState = {
  loading: false,
  data: [],
};

export default produce(
  (state: IFAQState = initialState, action: IFAQActions): IFAQState => {
    switch (action.type) {
      case FAQActionTypes.FETCH_STARTED:
        state.error = undefined;
        state.loading = true;
        break;
      case FAQActionTypes.FETCH_SUCCEED:
        state.loading = false;
        state.data = action.payload;
        break;
      case FAQActionTypes.FETCH_FAILED:
        state.loading = false;
        state.error = action.payload;
        break;
    }

    return state;
  },
);
