import styled from 'styled-components';

export const SimpleTable = styled.table`
  width: 100%;

  &,
  th,
  td {
    border: 1px solid #f0f0f0;
  }

  th,
  td {
    padding: 8px;
  }
`;
