import React, { FC, memo, useMemo } from 'react';
import { Form, Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, FieldInputProps, FieldMetaProps, useField } from 'formik';
import { twoLevelShallowEqualObjects } from '@core/utils';

export type TextAreaFieldProps = FieldConfig & { item?: FormItemProps; input?: TextAreaProps };
type MemoizedTextAreaFieldProps = {
  item?: FormItemProps;
  input?: TextAreaProps;
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
};

const MemoizedTextAreaField = memo<MemoizedTextAreaFieldProps>(({ item, field, input, meta }) => {
  return (
    <Form.Item {...item} validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error}>
      <Input.TextArea {...field} {...input} />
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const TextAreaField: FC<TextAreaFieldProps> = ({ item, input, ...props }) => {
  const [field, meta] = useField(props);

  const newField = useMemo(
    () => ({
      ...field,
      onChange: field.onChange(field.name),
    }),
    [field],
  );

  return <MemoizedTextAreaField field={newField} meta={meta} item={item} input={input} />;
};
