import React, { Context, FC, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react';
import { useDebounceEffect } from '@core/utils';

import { nextTableReducer, nextTableState } from './reducer';
import { TableContext, TableFetchParams, TableState } from '../types';
import {
  nextTableFetchDataFailedAction,
  nextTableFetchDataStartedAction,
  nextTableFetchDataSucceedAction,
  nextTableMergeStateAction,
  nextTableResetAction,
  nextTableSelectAllAction,
  nextTableSetFiltersAction,
  nextTableSetFiltersByIdAction,
  nextTableSetGlobalFilterAction,
  nextTableSetPageIndexAction,
  nextTableSetPageSizeAction,
  nextTableSetSelectedRowIdsAction,
  nextTableSetSortByAction,
} from './actions';
import { useSelection } from '../hooks';
import { TableActions, TableCacheContext } from '@core/table';

export const TableProvider: FC<{
  context: Context<TableContext>;
  name?: string;
  useCache?: boolean;
  onFetch: (params: TableFetchParams) => Promise<{ data: any[]; total: number }>;
  onGlobalFilter?: (data: any[], filter: string) => any[];
  defaultState?: Partial<TableState>;
}> = ({ children, context, name, onFetch, defaultState, useCache = true, onGlobalFilter }) => {
  const tableCache = useContext(TableCacheContext);
  const cachedState = useMemo(() => (name && tableCache.get(name)) || {}, [name, tableCache]);
  const [baseState, baseDispatch] = useReducer(nextTableReducer, useCache ? { ...nextTableState, ...defaultState, ...cachedState } : { ...nextTableState, ...defaultState });

  const state = useMemo(
    () => ({
      ...baseState,
      data: onGlobalFilter?.(baseState.data, baseState.globalFilter) || baseState.data,
    }),
    [baseState, onGlobalFilter],
  );

  const stateRef = useRef<TableState>(state);
  const selection = useSelection(state.selectedRowIds);
  const mounted = useRef(true);

  const dispatch = useCallback<React.Dispatch<TableActions>>((action) => {
    if (mounted.current) {
      baseDispatch(action);
    }
  }, []);

  useImperativeHandle(stateRef, () => state, [state]);

  const setFilters = useCallback(
    (filters) => {
      dispatch(nextTableSetFiltersAction(filters));
    },
    [dispatch],
  );

  const setFilterById = useCallback(
    (id: string, name: any) => {
      dispatch(nextTableSetFiltersByIdAction(id, name));
    },
    [dispatch],
  );

  const setSortBy = useCallback(
    (sortBy) => {
      dispatch(nextTableSetSortByAction(sortBy));
    },
    [dispatch],
  );

  const setPageIndex = useCallback(
    (pageIndex) => {
      dispatch(nextTableSetPageIndexAction(pageIndex));
    },
    [dispatch],
  );

  const setPageSize = useCallback(
    (pageSize) => {
      dispatch(nextTableSetPageSizeAction(pageSize));
    },
    [dispatch],
  );

  const setSelectedRowIds = useCallback(
    (selectedRowIds) => {
      dispatch(nextTableSetSelectedRowIdsAction(selectedRowIds));
    },
    [dispatch],
  );

  const fetch = useCallback(async () => {
    try {
      dispatch(nextTableFetchDataStartedAction());

      const result = await onFetch({
        filters: stateRef.current.filters,
        sortBy: stateRef.current.sortBy,
        pageIndex: stateRef.current.pageIndex,
        pageSize: stateRef.current.pageSize,
      });

      dispatch(nextTableFetchDataSucceedAction({ data: result.data, total: result.total }));
    } catch (e) {
      dispatch(nextTableFetchDataFailedAction(e.message));
    }
  }, [dispatch, onFetch]);

  const reset = useCallback(() => dispatch(nextTableResetAction()), [dispatch]);
  const selectAll = useCallback(() => dispatch(nextTableSelectAllAction()), [dispatch]);
  const resetSelection = useCallback(() => dispatch(nextTableSetSelectedRowIdsAction({})), [dispatch]);

  const setGlobalFilter = useCallback((value: string) => dispatch(nextTableSetGlobalFilterAction(value)), [dispatch]);

  const mergeState = useCallback(
    (state: Partial<TableState>) => {
      dispatch(nextTableMergeStateAction(state));
    },
    [dispatch],
  );

  useDebounceEffect(
    useCallback(async () => {
      try {
        dispatch(nextTableFetchDataStartedAction());
        const result = await onFetch({ filters: state.filters, sortBy: state.sortBy, pageIndex: state.pageIndex, pageSize: state.pageSize });
        dispatch(nextTableFetchDataSucceedAction({ data: result.data, total: result.total }));
      } catch (e: any) {
        dispatch(nextTableFetchDataFailedAction(e.message));
      }
    }, [dispatch, onFetch, state.filters, state.pageIndex, state.pageSize, state.sortBy]),
    300,
  );

  useEffect(() => {
    if (name && useCache) {
      tableCache.set(name, state);
    }
  }, [name, state, tableCache, useCache]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <context.Provider
      value={{
        state,
        dispatch,
        setFilters,
        setSortBy,
        setPageIndex,
        setPageSize,
        setSelectedRowIds,
        setGlobalFilter,
        fetch,
        reset,
        selectAll,
        resetSelection,
        setFilterById,
        selection,
        mergeState,
        name,
      }}
    >
      {children}
    </context.Provider>
  );
};
