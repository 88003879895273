export const getBase64 = (file: File): Promise<string | null> => {
  return new Promise<string | null>((resolve) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => resolve(null);
    } catch (e) {
      resolve(null);
    }
  });
};
