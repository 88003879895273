import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import auth from './auth';
import table from './table';
import shops from './shops';
import faq from './faq';
import news from './news';
import counter from './counter';
import theme from './theme';
import banners from './banners';

const reducers = { auth, table, shops, faq, news, counter, theme, banners, form };

export default combineReducers(reducers);
