export interface IEventBus {
  subscribe(eventType: string, callback: BusSubscriber, receiveCold?: number): void;

  publish(event: BusEvent, temporary?: boolean): void;

  unsubscribe(eventType: string, callback: BusSubscriber): void;
}

export type BusSubscriber<Payload = any> = (payload: Payload, cold?: boolean) => void;

export type BusEvent<Payload = any> = { type: string; payload?: Payload };

export const EventBusType = Symbol.for('EventBus');
